import archivedDocuments from 'ducks/archivedDocuments'
import auth from 'ducks/auth'
import documents from 'ducks/documents'
import notifications from 'ducks/notifications'
import permissions from 'ducks/permissions'
import user from 'ducks/user'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


const reducers = combineReducers({
  auth,
  notifications,
  user,
  permissions,
  documents,
  archivedDocuments,
})

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default {
  reducer: reducers,
  persistedReducer: persistedReducer,
}
