import { createSlice } from '@reduxjs/toolkit'
import API from 'core/api'
import createModel from 'utils/createModel'

const handleRequest = (state) => {
  state.isFetching = true
}

const handleError = (state, { payload }) => {
  state.isFetching = false
  state.errors = payload
}

const handleSetPermissions = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.data = payload
}

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    isFetching: null,
    errors: {},
    data: [],
  },
  reducers: {
    requestIsFetching: handleRequest,
    requestError: handleError,
    setPermissions: handleSetPermissions,
  },
})

export const {
  requestIsFetching,
  requestError,
  setPermissions,
} = permissionsSlice.actions

export const getPermissions = () => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await API.getPermissions(token)
    const permissions = createModel(res.data, 'Permission')
    dispatch(setPermissions(permissions))
  } catch (err) {
    dispatch(requestError(err))
  }
}

export default permissionsSlice.reducer
