import { createSlice } from '@reduxjs/toolkit'
import BUSINESS_API from 'core/api-business'
import createModel from 'utils/createModel'

const handleRequest = (state) => {
  state.isFetching = true
}

const handleError = (state, { payload }) => {
  state.isFetching = false
  state.errors = payload
}

const handleSuccess = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  if (state.page < payload.page) {
    state.data.push(...payload.data)
  } else {
    state.data = payload.data
  }
  state.totalCount = Number(payload.count)
  state.page = payload.page
}

const handleEditUser = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.data = state.data.map((user) => {
    if (user.id === payload.id) return payload
    return user
  })
}

const handleDeleteUser = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.data = state.data.filter((user) => {
    return user.id !== payload
  })
  state.page = 1
}

const initialState = {
  isFetching: null,
  errors: {},
  data: [],
  totalCount: 0,
  page: 0,
}

const usersSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {
    requestIsFetching: handleRequest,
    requestError: handleError,
    requestSuccess: handleSuccess,
    requestOnEdit: handleEditUser,
    requestOnDelete: handleDeleteUser,
    clearState: () => initialState
  },
})

export const {
  requestIsFetching,
  requestError,
  requestSuccess,
  requestOnEdit,
  requestOnDelete,
  clearState
} = usersSlice.actions

export default usersSlice.reducer

const normalizeResponse = (response, page) => {
  if (!response.data.length) {
    return {
      data: response.data,
      count: response.headers['x-total-count'],
      page: 0,
    }
  }

  const users = createModel(response.data, 'User')
  return {
    data: users,
    count: response.headers['x-total-count'],
    page,
  }
}

// Fetch users
export const getUsers = (
  filters,
  search,
  page = 0,
  sortBy,
  sortOrder
) => async (dispatch, getState) => {
  const token = getState().auth.token
  const filter =
    filters && filters.length && filters.map((filter) => '&' + filter).join('')
  const sort = sortBy && sortOrder ? `&sort=${sortBy}&order=${sortOrder}` : ''
  if ((filters || search || sortBy) && page === 1) {
    dispatch(requestIsFetching())
  }
  try {
    const response = await BUSINESS_API.getUsers(
      token,
      filter,
      search,
      page,
      sort
    )

    dispatch(requestSuccess(normalizeResponse(response, page)))
  } catch (err) {
    const error = (err.response && err.response.data) ? err.response.data : err
    dispatch(requestError(error))
  }
}

export const getGlobalUsers = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await BUSINESS_API.getGlobalUsers(data, token)
    const users = createModel(response.data, 'User')
    return Promise.resolve(users)
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const inviteUsers = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await BUSINESS_API.inviteUsers(data, token)
    const user = createModel(response.data, 'User')
    dispatch(requestOnEdit(user))
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const approveInvitation = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await BUSINESS_API.approveInvitation(data, token)
    const user = createModel(response.data, 'User')
    dispatch(requestOnEdit(user))
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const editUser = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await BUSINESS_API.editUser(data, token)
    const user = createModel(response.data, 'User')
    dispatch(requestOnEdit(user))
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const deleteUser = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await BUSINESS_API.deleteUser(data.id, token)
    dispatch(requestOnDelete(data.id))
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const activateUser = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const { id } = data
    const response = await BUSINESS_API.activateUser(id, token)
    const user = createModel(response.data, 'User')
    dispatch(requestOnEdit(user))
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const suspendUser = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const { id } = data
    const response = await BUSINESS_API.suspendUser(id, token)
    const user = createModel(response.data, 'User')
    dispatch(requestOnEdit(user))
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const clearUsersState = () => (dispatch) => {
  dispatch(clearState())
}