import React, { createContext } from 'react'

import {
  setConnected,
  setApiError,
  setNotifications,
  notificationSync
} from 'ducks/notifications'
import { useSelector, useDispatch } from 'react-redux'
import io from 'socket.io-client'
import createModel from 'utils/createModel'

const WebSocketContext = createContext(null)

export { WebSocketContext }

export default ({ children }) => {
  let socket
  let ws

  const dispatch = useDispatch()
  const authToken = useSelector((state) => state.auth.token)

  // Usage in component inside ws context:
  // import { WebSocketContext } = 'WebSocket'
  // const ws = useContext(WebSocketContext)
  // ws.markNotificationSeen(notificationId)
  /**
   * Mark notification as seen in web. This will trigger updated 'notification' to be pushed.
   * @param {String} notificationId
   */
  const markNotificationSeen = (notificationId) => {
    socket.emit('clientNotificationSeen', notificationId)
  }

  if (!socket && authToken) {
    // initialize socket connection
    socket = io(process.env.REACT_APP_WS_API_URL, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      },
    })

    // connection errors
    socket.on('error', (err) => {
      if (err.type === 'UnauthorizedError') {
        // authorization failed
        console.error('ws auth error:', err)
      } else {
        console.error('ws error:', err)
      }
      socket.close()
      socket = null
    })

    // application errors
    socket.on('apiError', (err) => {
      dispatch(setApiError(err))
    })

    socket.on('connect', () => {
      dispatch(setConnected(true))
    })

    socket.on('disconnect', () => {
      dispatch(setConnected(false))
    })

    socket.on('notifications', (notifications) => {
      dispatch(setNotifications(createModel(notifications, 'Notification')))
    })

    socket.on('notification', (notification) => {
      dispatch(notificationSync(createModel(notification, 'Notification')))
    })

    ws = {
      socket,
      markNotificationSeen,
    }
  }

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  )
}
