import React from 'react'

import * as Sentry from '@sentry/react'
import TimeAgo from 'javascript-time-ago'
import bg from 'javascript-time-ago/locale/bg'
import en from 'javascript-time-ago/locale/en'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import factory from './core/store'
import * as serviceWorker from './serviceWorker'

import './i18n'

TimeAgo.addLocale(en)
TimeAgo.addLocale(bg)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  normalizeDepth: 3,
})

const { store, persistor } = factory()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Route path='/' component={App} />
          </BrowserRouter>
        </PersistGate>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
