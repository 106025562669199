import { createSlice } from '@reduxjs/toolkit'
import API from 'core/api'
import createModel from 'utils/createModel'

const handleRequest = (state) => {
  state.isFetching = true
  state.errors = {}
}

const handleRequestFetched = (state) => {
  state.isFetching = false
  state.errors = {}
}

const handleError = (state, { payload }) => {
  state.isFetching = false
  state.errors = payload
}

const handleSetDocuments = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  if (state.page < payload.page) {
    state.data.push(...payload.documents)
  } else {
    state.data = payload.documents
  }
  state.totals = payload.totals
  state.totalCount = payload.count
  state.page = payload.page
}


const initialState = {
  isFetching: null,
  errors: {},
  data: [],
  totals: [],
  totalCount: 0,
  page: 0,
}
const archivedDocumentsSlice = createSlice({
  name: 'archivedDocuments',
  initialState: initialState,
  reducers: {
    requestIsFetching: handleRequest,
    requestIsFetched: handleRequestFetched,
    requestError: handleError,
    setDocuments: handleSetDocuments,
    clearState: () => initialState
  },
})

export const {
  requestIsFetching,
  requestIsFetched,
  requestError,
  setDocuments,
  clearState
} = archivedDocumentsSlice.actions

const options = {
  items: [
    'All (default)',
    'Yesterday',
    'Last 7 days',
    'Last 30 days',
    'Year ago',
  ],
  values: [
    '',
    new Date().setDate(new Date().getDate() - 1),
    new Date().setDate(new Date().getDate() - 7),
    new Date().setMonth(new Date().getMonth() - 1),
    new Date().setFullYear(new Date().getFullYear() - 1),
  ],
}

const normalizeResponse = (response, page) => {
  const documents = createModel(response.data.documents, 'Document')
  if (!response.data.documents.length) {
    return {
      documents: documents,
      count: response.headers['x-total-count'],
      page: 0,
      totals: response.data.totals,
    }
  }

  return {
    documents: documents,
    count: response.headers['x-total-count'],
    page,
    totals: response.data.totals,
  }
}

export const getArchivedDocuments = (
  status,
  search,
  sortBy,
  sortOrder,
  filters,
  page = 0,
  archived = false
) => async (dispatch, getState) => {
  const token = getState().auth.token
  if ((filters || search || sortBy) && page === 1) {
    dispatch(requestIsFetching())
  }
  try {
    const selectedFilter =
      filters &&
      options.values.find((el, i) => i === options.items.indexOf(filters))

    const filter = selectedFilter && `&createdAtFrom=${selectedFilter}`
    const sort = sortBy && sortOrder ? `&sort=${sortBy}&order=${sortOrder}` : ''
    const res = await API.getDocuments(
      token,
      status,
      search,
      sort,
      filter,
      page,
      archived
    )
    const documents = normalizeResponse(res, page)
    dispatch(setDocuments(documents))
    return documents
  } catch (err) {
    dispatch(requestError(err.response.data))
  }
}

export default archivedDocumentsSlice.reducer
