import React, { useContext } from 'react'

import { getEntity } from 'Business/ducks/entity'
import { WebSocketContext } from 'components/WebSocket'
import { getUser } from 'ducks/user'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

const modifyNotificationText = (notification, t) => {
  let notificationText = ''
  if (notification.type === 'documentParticipantPendingAction') {
    notificationText =
      t(notification.title) +
      t(' for document with Id') +
      ': ' +
      notification.subject.id
  }

  if (notification.type === 'documentParticipantAdded') {
    notificationText =
      t('You have been added as participant in a new document') +
      t(' with Id') +
      ': ' +
      notification.subject.id
  }

  if (notification.type === 'documentDone') {
    notificationText =
      t('Document with Id') + ': ' + notification.subject.id + t(' is ready')
  }

  if (notification.type === 'documentRejected') {
    notificationText =
      t('Document with Id') + ': ' + notification.subject.id + t(' is rejected')
  }

  if (notification.type === 'documentExpired') {
    notificationText =
      t('Document with Id') + ': ' + notification.subject.id + t(' is expired')
  }

  if (notification.type === 'administrativeDocumentRequest') {
    notificationText =
      t('An administrative document with Id') +
      ': ' +
      notification.subject.id +
      t(' is waiting for your approval')
  }

  if (notification.type === 'administrativeDocumentDone') {
    notificationText =
      t('An administrative document with Id') +
      ': ' +
      notification.subject.id +
      t(' is ready to be used')
  }

  if (notification.type === 'administrativeDocumentRejected') {
    notificationText =
      t('An administrative document with Id') +
      ': ' +
      notification.subject.id +
      t(' is rejected')
  }

  if (notification.type === 'administrativeDocumentRevoked') {
    notificationText =
      t('An administrative document with Id') +
      ': ' +
      notification.subject.id +
      t(' is revoked')
  }

  if (notification.type === 'userIdVerificationSuccess') {
    notificationText = notification.title
  }

  if (notification.type === 'userIdVerificationReject') {
    notificationText = notification.title
  }

  if (notification.type === 'userRequiresInvite') {
    notificationText = notification.title
  }

  return notificationText
}
const ToastContent = ({ notification }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['settings', 'common'])
  const history = useHistory()
  const ws = useContext(WebSocketContext)
  const tenant = process.env.REACT_APP_TENANT

  const notificationText = modifyNotificationText(notification, t)

  // Fetch user and entity data and refresh page
  if (notification.type === 'userIdVerificationSuccess' || notification.type === 'userIdVerificationReject') {
    dispatch(getUser())
    tenant === 'BUSINESS' && dispatch(getEntity())
    window.location.reload()
  }

  const onNotificationClick = () => {
    ws.markNotificationSeen(notification.id)

    if (notification.subjectUrl) {
      window.location = notification.subjectUrl
    } else if (notification.type === 'userRequiresInvite') {
      history.push('/users')
    } else {
      history.push('/notifications', { notificationId: notification.id })
    }
  }

  return <div onClick={() => onNotificationClick()}>{notificationText}</div>
}

export default ToastContent
