import { createSlice } from '@reduxjs/toolkit'
import BUSINESS_API from 'core/api-business'
import createModel from 'utils/createModel'

const handleRequest = (state) => {
  state.isFetching = true
}

const handleError = (state, { payload }) => {
  state.isFetching = false
  state.errors = payload
}

const handleSuccess = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.data = payload
}

const handleActivationViewChange = (state, { payload }) => {
  state.activationView = payload
}

const initialState = {
  isFetching: null,
  errors: {},
  step: 0,
  data: [],
  activationView: true,
}

const entitySlice = createSlice({
  name: 'entity',
  initialState: initialState,
  reducers: {
    requestIsFetching: handleRequest,
    requestError: handleError,
    stepChange: (state, { payload }) => {
      state.step = payload
    },
    requestSuccess: handleSuccess,
    updateEntityRepresentative: (state, { payload }) => {
      state.isFetching = false
      if (!state.data.representatives) state.data.representatives = [payload]

      state.data.representatives = state.data.representatives.map((rep) => {
        if (rep.id !== payload.id) return rep
        return payload
      })
    },
    setActivationView: handleActivationViewChange,
    clearState: () => initialState,
  },
})

export const {
  requestIsFetching,
  requestError,
  requestSuccess,
  stepChange,
  updateEntityRepresentative,
  setActivationView,
  clearState
} = entitySlice.actions

export default entitySlice.reducer

const normalizeResponse = (response) => {
  if (response && response.data && response.data._id) {
    response.data['id'] = response.data['_id']
    delete response.data['_id']
  }
  let { entity } = response && response.data
  entity['id'] = entity['_id']
  delete entity['_id']
  if (entity && entity.representatives && entity.representatives.length) {
    entity.representatives.map((r) => {
      r['id'] = r['_id']
      delete r['_id']
      return r
    })
  }

  return entity
}

// Fetch entity
export const getEntity = () => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await BUSINESS_API.getEntity(token)
    const entity = createModel(response.data.entity, 'Entity')
    dispatch(requestSuccess(entity))
  } catch (err) {
    let error = err.response.data
    error = error.message ? error : { message: error }
    dispatch(requestError(error))
  }
}

export const addRepresentatives = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await BUSINESS_API.addRepresentatives(
      { representatives: [data] },
      token
    )
    const entity = createModel(response.data.entity, 'Entity')
    dispatch(requestSuccess(entity))
    return entity
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const editRepresentative = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const { id, ...otherData } = data
    const response = await BUSINESS_API.editRepresentative(
      { id, data: { ...otherData } },
      token
    )
    const entity = createModel(response.data.entity, 'Entity')
    dispatch(requestSuccess(entity))
    return entity
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const removeRepresentatives = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await BUSINESS_API.removeRepresentatives(
      { ids: [data.id] },
      token
    )
    const entity = createModel(response.data.entity, 'Entity')
    dispatch(requestSuccess(entity))
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const inviteRepresentative = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await BUSINESS_API.inviteRepresentative(
      { id: data.id, email: data.email },
      token
    )
    const representative = createModel(response, 'Representative')
    dispatch(updateEntityRepresentative(representative))
    return Promise.resolve(representative)
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const selfAssignToRepresentative = (data) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await BUSINESS_API.selfAssignToRepresentative(
      { representativeId: data },
      token
    )
    // Note: representative here, not entity - reponse reformatted in order to match normalize fn
    const responseData = {
      data: {
        entity: response.data,
      },
    }
    const normalized = normalizeResponse(responseData)
    dispatch(updateEntityRepresentative(normalized))
    return Promise.resolve(normalized)
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const clearEntityState = () => async (dispatch) => {
  dispatch(clearState())
}