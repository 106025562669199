import { createSlice } from '@reduxjs/toolkit'
import BUSINESS_API from 'core/api-business'
import createModel from 'utils/createModel'

const handleRequest = (state) => {
  state.isFetching = true
  state.errors = {}
}

const handleRequestFetched = (state) => {
  state.isFetching = false
  state.errors = {}
}

const handleError = (state, { payload }) => {
  state.isFetching = false
  state.errors = payload
}

const handleSetDocuments = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.data = payload
}

const handleAddDocument = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.data.push(payload)
}

const handleEditDocument = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.data = state.data.map((doc) => {
    if (doc.id === payload.id) return payload
    return doc
  })
}

const handleRemoveDocument = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.data = state.data.filter((doc) => doc.id !== payload)
}

const administrativeDocumentsSlice = createSlice({
  name: 'administrativeDocuments',
  initialState: {
    isFetching: null,
    errors: {},
    data: [],
  },
  reducers: {
    requestIsFetching: handleRequest,
    requestIsFetched: handleRequestFetched,
    requestError: handleError,
    setDocuments: handleSetDocuments,
    addDocument: handleAddDocument,
    editDocument: handleEditDocument,
    removeDocument: handleRemoveDocument,
  },
})

export const {
  requestIsFetching,
  requestIsFetched,
  requestError,
  setDocuments,
  addDocument,
  editDocument,
  removeDocument,
} = administrativeDocumentsSlice.actions

export const getAdministrativeDocuments = (status) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await BUSINESS_API.getAdministrativeDocuments(token, status)

    const documents = createModel(res.data, 'AdministrativeDocument')
    dispatch(setDocuments(documents))
    return documents
  } catch (err) {
    dispatch(requestError(err.response.data))
  }
}

export const requestAdministrativeDocument = (data) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await BUSINESS_API.requestAdministrativeDocument(token, data)
    const document = createModel(res.data, 'AdministrativeDocument')
    return document
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const createAdministrativeDocument = (data) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    let formData = new FormData()
    formData.append('administrativeDocument', data)
    const res = await BUSINESS_API.createAdministrativeDocument(formData, token)

    const administrativeDocument = createModel(
      res.data,
      'AdministrativeDocument'
    )
    return administrativeDocument
  } catch (err) {
    dispatch(requestError(err.response.data))
  }
}

export const approveAdministrativeDocument = (data) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await BUSINESS_API.approveAdministrativeDocument(token, data)

    const administrativeDocument = createModel(
      res.data,
      'AdministrativeDocument'
    )
    return administrativeDocument
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const requestSignAdministrativeDocument = (data) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await BUSINESS_API.requestSignAdministrativeDocument(token, data)

    const administrativeDocument = createModel(
      res.data,
      'AdministrativeDocument'
    )
    return administrativeDocument
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}



export const signAdministrativeDocument = (data) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await BUSINESS_API.signAdministrativeDocument(token, data)

    const administrativeDocument = createModel(
      res.data,
      'AdministrativeDocument'
    )
    return administrativeDocument
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const rejectAdministrativeDocument = (id) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await BUSINESS_API.rejectAdministrativeDocument(token, id)

    const administrativeDocument = createModel(
      res.data,
      'AdministrativeDocument'
    )
    return administrativeDocument
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const revokeAdministrativeDocument = (id) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await BUSINESS_API.revokeAdministrativeDocument(token, id)

    const administrativeDocument = createModel(
      res.data,
      'AdministrativeDocument'
    )
    return administrativeDocument
  } catch (err) {
    return Promise.reject(err.response.data)
  }
}

export const getAdministrativeDocument = (id) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await BUSINESS_API.getAdministrativeDocument(token, id)
    const administrativeDocument = createModel(
      res.data,
      'AdministrativeDocument'
    )
    dispatch(requestIsFetched())
    return administrativeDocument
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const getAdministrativeDocumentFile = (id) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const res = await BUSINESS_API.getAdministrativeDocumentFile(token, id)
    const blob = new Blob([res.data], { type: 'application/pdf' })
    const objectUrl = window.URL.createObjectURL(blob)
    dispatch(requestIsFetched())
    return objectUrl
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export default administrativeDocumentsSlice.reducer
