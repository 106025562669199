const breakpoints = ['40em', '52em', '64em', '80em']
// aliases
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

export const theme = {
  /**
   * Colors are named using Name that color: http://chir.ag/projects/name-that-color/
   */
  colors: {
    caribbeanGreen: '#04D1A3',
    lightCaribbeanGreen: '#04D1A31A',
    dodgerBlue: '#2273F7',
    whiteLilac: '#F2F2FB',
    ebonyClay: '#2C3642',
    santasGrey: '#9EA3B7',
    white: '#FFFFFF',
    greyShadow: '#022d7233',
    transparent: 'transparent',
    athensGrey: '#F1F1F6',
    amaranth: '#E83A48',
    carnation: '#F43F55',
    botticelli: '#DCE3ED',
    orange: '#FF681F',
    yellowgreen: '#C5E17A',
    japaneseLaurel: '#008000',
    mischka: '#CED0DA',
    laPalma: '#159715',
    silverSand: '#C2C5C9',
    ripeLemon: '#F6C510',
    ecstasy: '#F67E10',
    linkWater: '#F7F7FD',
    nepal: '#8DA2C3',
    hawkesBlue: '#DBE8FE',
    grannyApple: '#D9F6EC',
  },
  fontSizes: ['0.875rem', '1rem', '1.125rem', '1.5rem', '2.5rem', '3.375rem'],
  space: [
    '0rem',
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.5rem',
    '2rem',
    '3rem',
    '3.375rem',
    '4rem',
    '6rem',
    '8rem',
    '12rem',
    '16rem',
    '24rem',
  ],
  breakpoints,
  transition: 'all 300ms ease-in-out',
  globalBorderRadius: '3px',
  globalOutline: 'auto 1px #F1F1F6',
}

export const personalTheme = {
  images: {
    logo: 'logo-personal',
    mockup: 'notebook',
    mockupType: 'notebook',
  },
  background: theme.colors.lightCaribbeanGreen,
  buttonColor: theme.colors.caribbeanGreen,
  secondaryTextColor: theme.colors.caribbeanGreen,
  notificationBackground: theme.colors.grannyApple,
  emptyStateBackground: theme.colors.grannyApple,
}

export const businessTheme = {
  images: {
    logo: 'logo-business',
    mockup: 'macbook',
    mockupType: 'macbook',
  },
  background: theme.colors.whiteLilac,
  buttonColor: theme.colors.dodgerBlue,
  secondaryTextColor: theme.colors.dodgerBlue,
  notificationBackground: theme.colors.hawkesBlue,
  emptyStateBackground: theme.colors.hawkesBlue,
}
