import axios from 'axios'
import getCookie from 'utils/getCookie'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}api`,
})

/**
 * In case user is not authenticated we should send the current user locale as a header parameter to the backend so the response be in the same language as users'.
 */
instance.interceptors.request.use((req) => {
  const persistedInfo = JSON.parse(localStorage.getItem('persist:root'))
  if (persistedInfo && persistedInfo.auth) {
    const authData = JSON.parse(persistedInfo.auth)
    if (!authData.isAuthenticated) {
      req.headers.locale = getCookie('i18nextLng')
    }
  }
  return req
})

const config = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
}

const BUSINESS_API = {
  loginStep1: (data) => {
    return instance.post('/auth/login-step-1', data)
  },
  loginStep2: (data, token) => {
    return instance.post('/auth/login-step-2', data, config(token))
  },
  getPermissions: (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    return instance.get('/permissions', config)
  },
  createAccount: ({ email, type, activationCode }) => {
    return instance.post('/auth/create-account', {
      email,
      type,
      activationCode,
    })
  },
  validateEmail: ({ code }, token) => {
    return instance.post('/auth/validate-email', { code }, config(token))
  },
  updatePhone: ({ phone }, token) => {
    return instance.post('/auth/update-phone', { phone }, config(token))
  },
  validatePhone: ({ code }, token) => {
    return instance.post('/auth/validate-phone', { code }, config(token))
  },
  completeAccount: (data, token) => {
    return instance.post('/auth/complete-account', data, config(token))
  },
  completeAccountInvitation: (data, token) => {
    return instance.post(
      `/auth/complete-account-invitation`,
      data,
      config(token)
    )
  },
  passwordResetRequest: (data) => {
    return instance.post('/auth/request-password-reset', data)
  },
  passwordResetStep1: (data, token) => {
    return instance.post('/auth/password-reset-step-1', data, config(token))
  },
  passwordResetStep2: (data, token) => {
    return instance.post('/auth/password-reset-step-2', data, config(token))
  },
  resendValidationSMS: (data, token) => {
    return instance.post('/auth/resend-validation-sms', data, config(token))
  },
  resendValidationEmail: (data, token) => {
    return instance.post('/auth/resend-validation-email', data, config(token))
  },
  getEntity: (token) => {
    return instance.get('/entity', config(token))
  },
  addRepresentatives: (data, token) => {
    return instance.post('/entity/add-representatives', data, config(token))
  },
  editRepresentative: (data, token) => {
    return instance.post('/entity/edit-representative', data, config(token))
  },
  removeRepresentatives: (data, token) => {
    return instance.post('/entity/remove-representatives', data, config(token))
  },
  inviteRepresentative: (data, token) => {
    return instance.post('/entity/invite-representative', data, config(token))
  },
  selfAssignToRepresentative: (data, token) => {
    return instance.post(
      `/representative/${data.representativeId}/self-assign`,
      {},
      config(token)
    )
  },
  getVerificationAuthToken: (token) => {
    return instance.get('/user-verification/auth-token', config(token))
  },
  completeUserVerification: (data, token) => {
    return instance.post('/user-verification/complete', data, config(token))
  },
  getUserInfo: (token) => {
    return instance.get('/profile', config(token))
  },
  updateUser: (data, token) => {
    return instance.post('/profile/update', data, config(token))
  },
  uploadAvatar: (data, token) => {
    return instance.post('/profile/upload-avatar', data, config(token))
  },
  getDocuments: (token, status, search, sort, filter, page, archived) => {
    return instance.get(
      `/documents?perPage=15${status ? `&status=${status}` : ''}${
        search ? `&search=${search}` : ''
      }${sort}${filter}${page ? `&page=${page}` : ''}${
        archived ? `&archived=true` : ''
      }`,
      config(token)
    )
  },
  getDocument: (token, id) => {
    return instance.get(`/documents/${id}`, config(token))
  },
  getDocumentFile: (token, id) => {
    const options = config(token)
    options.responseType = 'blob'
    return instance.get(`/documents/${id}/file`, options)
  },
  getDocumentLogs: (token, id) => {
    return instance.get(`/documents/${id}/logs`, config(token))
  },
  createDocument: (data, token) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    }
    return instance.post('/documents', data, headers)
  },
  publishDocument: (id, data, token) => {
    return instance.patch(`/documents/${id}/publish`, data, config(token))
  },
  updateDocument: (data, token) => {
    const updateProps = Object.keys(data).filter((key) => key !== 'id')
    const payload = updateProps.reduce((acc, key) => {
      acc[key] = data[key]
      return acc
    }, {})
    return instance.patch(`/documents/${data.id}`, payload, config(token))
  },
  archiveDocument: (id, token) => {
    return instance.patch(`/documents/${id}/archive`, [], config(token))
  },
  unarchiveDocument: (id, token) => {
    return instance.patch(`/documents/${id}/unarchive`, [], config(token))
  },
  rejectDocument: (data, token) => {
    return instance.patch(
      `/documents/${data.id}/reject`,
      { reason: data.reason },
      config(token)
    )
  },
  deleteDocument: (documentId, token) => {
    return instance.delete(`/documents/${documentId}`, config(token))
  },
  signRequest: (data, token) => {
    return instance.patch(`/documents/${data}/sign/request`, [], config(token))
  },
  signDocument: (data, token) => {
    return instance.patch(
      `/documents/${data.id}/sign`,
      { signRequestCode: data.code },
      config(token)
    )
  },
  approveDocument: (data, token) => {
    return instance.patch(`/documents/${data}/approve`, [], config(token))
  },
  getAdministrativeDocuments: (token, status = '') => {
    if (status) status = '?status=' + status
    return instance.get(`/administrative-documents${status}`, config(token))
  },
  requestAdministrativeDocument: (token, data) => {
    return instance.post(
      `/administrative-documents/request`,
      data,
      config(token)
    )
  },
  approveAdministrativeDocument: (token, { id, ...data }) => {
    return instance.post(
      `/administrative-documents/${id}/approve`,
      data,
      config(token)
    )
  },
  getAdministrativeDocument: (token, id) => {
    return instance.get(`/administrative-documents/${id}`, config(token))
  },
  getAdministrativeDocumentFile: (token, id) => {
    const options = config(token)
    options.responseType = 'blob'
    return instance.get(`/administrative-documents/${id}/file`, options)
  },
  rejectAdministrativeDocument: (token, id) => {
    return instance.post(
      `/administrative-documents/${id}/reject`,
      [],
      config(token)
    )
  },
  revokeAdministrativeDocument: (token, id) => {
    return instance.post(
      `/administrative-documents/${id}/revoke`,
      [],
      config(token)
    )
  },
  requestSignAdministrativeDocument: (token, id) => {
    return instance.patch(
      `/administrative-documents/${id}/sign/request`,
      [],
      config(token)
    )
  },
  signAdministrativeDocument: (token, data) => {
    return instance.patch(
      `/administrative-documents/${data.id}/sign`,
      { signRequestCode: data.code },
      config(token)
    )
  },
  createAdministrativeDocument: (data, token) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    }
    return instance.post(`/administrative-documents`, data, headers)
  },
  publishAdministrativeDocument: (id, data, token) => {
    return instance.patch(
      `/administrative-documents/${id}/publish`,
      data,
      config(token)
    )
  },
  updateAdministrativeDocument: (id, data, token) => {
    return instance.patch(
      `/administrative-documents/${id}`,
      data,
      config(token)
    )
  },
  administrativeDocumentSignRequest: (data, token) => {
    return instance.patch(
      `/administrative-documents/${data.id}/sign/request`,
      data,
      config(token)
    )
  },
  administrativeDocumentSign: (data, token) => {
    return instance.patch(
      `/administrative-documents/${data.id}/sign`,
      data,
      config(token)
    )
  },
  getUsers: (token, filters, search, page, sort) => {
    return instance.get(
      `/users?perPage=15${page ? `&page=${page}` : ''}${
        filters ? filters : ''
      }${search ? '&search=' + search : ''}${sort}`,
      config(token)
    )
  },
  getGlobalUsers: (data, token) => {
    return instance.post(`/users/global-search`, { query: data }, config(token))
  },
  inviteUsers: (data, token) => {
    return instance.post('/users/invite', data, config(token))
  },
  editUser: ({ id, ...otherData }, token) => {
    return instance.patch(`/users/${id}`, otherData, config(token))
  },
  deleteUser: (id, token) => {
    const headers = config(token)
    const data = { _id: id }
    return axios.delete(`${process.env.REACT_APP_API_URL}api/users/${id}`, {
      ...headers,
      data,
    })
  },
  activateUser: (data, token) => {
    return instance.patch(`/users/${data}/activate`, [], config(token))
  },
  suspendUser: (data, token) => {
    return instance.patch(`/users/${data}/suspend`, [], config(token))
  },
  approveInvitation: (data, token) => {
    return instance.post(`/users/${data}/approve-invitation`, [], config(token))
  },
}

export default BUSINESS_API
