import { createSlice } from '@reduxjs/toolkit'
import API from 'core/api'
import createModel from 'utils/createModel'

const handleRequest = (state) => {
  state.isFetching = true
}

const handleRequestFetched = (state) => {
  state.isFetching = false
}

const handleError = (state, { payload }) => {
  state.isFetching = false
  state.errors = payload
}

const handleSuccess = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.data = payload
}

const handleTabChange = (state, { payload }) => {
  state.settingsTab = payload
}

const initialState = {
  isFetching: null,
  errors: {},
  data: {},
  settingsTab: 'profile'
}
const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    requestIsFetching: handleRequest,
    requestError: handleError,
    requestSuccess: handleSuccess,
    requestFetched: handleRequestFetched,
    clearState: () => initialState,
    changeSettingsTab: handleTabChange,
  },
})

export const {
  requestIsFetching,
  requestError,
  requestSuccess,
  requestFetched,
  clearState,
  changeSettingsTab,
} = userSlice.actions

export default userSlice.reducer

// Fetch user
export const getUser = () => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.getUserInfo(token)
    const user = createModel(response.data, 'User')

    dispatch(requestSuccess(user))
  } catch (err) {
    let error = err.response.data
    error = error.message ? error : { message: error }
    dispatch(requestError(error))
  }
}

// Update user
export const updateUser = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.updateUser(data, token)
    const user = createModel(response.data, 'User')

    dispatch(requestFetched())
    return user
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

// Upload new user avatar
export const uploadAvatar = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    let formData = new FormData()
    formData.append('avatar', data)
    const response = await API.uploadAvatar(formData, token)
    const user = createModel(response.data, 'User')

    dispatch(requestSuccess(user))
    return user
  } catch (err) {
    dispatch(requestError(err))
    return Promise.reject(err.response.data)
  }
}

// Change user language
export const changeLanguage = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.updateUser({ locale: data }, token)
    const user = createModel(response.data, 'User')
    dispatch(requestSuccess(user))
    return user
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

// Identity Validation Started - user comleted verification and is waiting to be approved.
export const markIdentityValidationStarted = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.identityValidationStarted(data, token)
    const user = createModel(response.data, 'User')
    dispatch(requestSuccess(user))
    return user
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const clearUserState = () => (dispatch) => {
  dispatch(clearState())
}



export const request2FaMethod = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.request2FaMethod(data, token)
    const user = createModel(response.data.user, 'User')
    dispatch(requestSuccess(user))
    return response
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const confirm2FaMethod = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.confirm2FaMethod(data, token)
    const user = createModel(response.data.user, 'User')
    dispatch(requestSuccess(user))
    return response
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}