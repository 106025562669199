import React from 'react'

import { createSlice } from '@reduxjs/toolkit'
import ToastContent from 'components/ToastContent'
import { toast } from 'react-toastify'

const handleConnected = (state, { payload }) => {
  state.connected = payload
}

const handleApiError = (state, { payload }) => {
  state.errors = payload
}

const handleSetNotifications = (state, { payload }) => {
  state.errors = {}
  state.data = payload
}

const handleSyncNotification = (state, { payload }) => {
  state.errors = {}

  if (state.data.find((item) => item.id === payload.data.id)) {
    // edit
    state.data = state.data.map((item) => {
      if (item.id !== payload.data.id) {
        return item
      }
      return payload.data
    })
  } else {
    if (payload.idValid) {
      // add
      toast(<ToastContent notification={payload.data} />)
    }
    state.data = [payload.data, ...state.data]
  }
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    connected: false,
    errors: {},
    data: [],
  },
  reducers: {
    setConnected: handleConnected,
    setApiError: handleApiError,
    setNotifications: handleSetNotifications,
    syncNotification: handleSyncNotification,
  },
})

export const {
  setConnected,
  setApiError,
  setNotifications,
  syncNotification,
} = notificationsSlice.actions

export const notificationSync = (payload) => async (dispatch, getState) => {
  const idValid = getState().user.data.idValid 
  dispatch(syncNotification({ data: payload, idValid }))
}

export default notificationsSlice.reducer
