import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'

const keyMap = {
  _id: 'id',
}

const replaceKeys = (data) => {
  if (isPlainObject(data)) {
    const mappedObject = {}
    for (const key in data) {
      const mappedKey = keyMap[key] || key

      mappedObject[mappedKey] = replaceKeys(data[key])
    }

    return mappedObject
  }

  if (isArray(data)) {
    return data.map((value) => replaceKeys(value))
  }

  return data
}

const createModelFromDTO = (data, modelType) => {
  const model = replaceKeys(data)

  model.__typename = modelType
  return model
}

const createModel = (data, modelType) => {
  if (isArray(data))
    return data.map((entry) => createModelFromDTO(entry, modelType))

  return createModelFromDTO(data, modelType)
}

export default createModel
