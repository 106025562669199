import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
    html, body {
        height: 100%;
        width: 100%;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: ${(props) => props.theme.fontSizes.sm};
        color: ${(props) => props.theme.colors.ebonyClay};

        @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
            font-size: ${(props) => props.theme.fontSizes.xs};
        }
    }

    #root {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
`;
