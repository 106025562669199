import { createSlice } from '@reduxjs/toolkit'
import API from 'core/api'

const handleRequest = (state) => {
  state.isFetching = true
}

const handleError = (state, { payload }) => {
  state.isFetching = false
  state.errors = payload
}

const handleSuccess = (state, { payload }) => {
  state.isFetching = false
  state.errors = {}
  state.token = payload.data.token
  state.tokenLevel = payload.data.tokenLevel
  state.isAuthenticated = payload.data.token && payload.data.tokenLevel === 5
  state.twofaMethod = payload.data && payload.data.twofaMethod
  state.timeLeft = 0
  state.forceSmsTwoFa = false
}

const handleCodeResendSuccess = (state) => {
  state.isFetching = false
  state.errors = {}
}

const handleSmsCodeResendSuccess = (state) => {
  state.isFetching = false
  state.errors = {}
  state.timeLeft = 60
}

const handleSendTwofaFallbackSms = (state) => {
  state.isFetching = false
  state.errors = {}
  state.timeLeft = 60
  state.forceSmsTwoFa = true
}

const handleSmsCodeTimeLeft = (state, { payload }) => {
  state.timeLeft = payload
}

const handleLogOut = (state) => {
  state.isFetching = false
  state.errors = {}
  state.token = undefined
  state.tokenLevel = 0
  state.isAuthenticated = false
  state.timeLeft = 0
  state.forceSmsTwoFa = false
}

const handleTokenSet = (state, { payload }) => {
  state.token = payload
  state.tokenLevel = 5
  state.isAuthenticated = true
}

const handleStoreReset = (state) => {
  state.isFetching = false
  state.errors = {}
  state.token = undefined
  state.tokenLevel = 0
  state.isAuthenticated = false
  state.timeLeft = 0
  state.forceSmsTwoFa = false
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isFetching: null,
    errors: {},
    isAuthenticated: false,
    tokenLevel: 0,
    token: undefined,
    type: '',
    timeLeft: 0,
    forceSmsTwoFa: false
  },
  reducers: {
    requestIsFetching: handleRequest,
    requestError: handleError,
    successPasswordResetRequest: handleSuccess,
    successPasswordResetStep1: handleSuccess,
    successPasswordResetStep2: handleSuccess,
    successLoginStep1: handleSuccess,
    successLoginStep2: handleSuccess,
    successCreateAccount: handleSuccess,
    successValidateEmail: handleSuccess,
    successUpdatePhone: handleSuccess,
    successValidatePhone: handleSuccess,
    successCompleteAccount: handleSuccess,
    successResendSmsCode: handleSmsCodeResendSuccess,
    successResendEmailCode: handleCodeResendSuccess,
    successTwofaFallbackSms: handleSendTwofaFallbackSms,
    smsCodeTimeLeftChange: handleSmsCodeTimeLeft,
    requestLogOut: handleLogOut,
    setToken: handleTokenSet,
    resetAuthStore: handleStoreReset,
  },
})

export const {
  requestIsFetching,
  requestError,
  successPasswordResetRequest,
  successPasswordResetStep1,
  successPasswordResetStep2,
  successLoginStep1,
  successLoginStep2,
  successCreateAccount,
  successValidateEmail,
  successUpdatePhone,
  successValidatePhone,
  successCompleteAccount,
  successResendSmsCode,
  successResendEmailCode,
  requestLogOut,
  setToken,
  resetAuthStore,
  smsCodeTimeLeftChange,
  successTwofaFallbackSms
} = authSlice.actions

export default authSlice.reducer

// Login
export const loginStep1 = (data) => async (dispatch) => {
  dispatch(requestIsFetching())
  try {
    const response = await API.loginStep1(data)
    dispatch(successLoginStep1(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const loginStep2 = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.loginStep2(data, token)
    dispatch(successLoginStep2(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

// Password reset
export const requestPasswordReset = (data) => async (dispatch) => {
  dispatch(requestIsFetching())
  try {
    const response = await API.passwordResetRequest(data)
    dispatch(successPasswordResetRequest(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const passwordResetStep1 = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.passwordResetStep1(data, token)
    dispatch(successPasswordResetRequest(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const passwordResetStep2 = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.passwordResetStep2(data, token)
    dispatch(successPasswordResetRequest(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

// Sign up flow
export const createAccount = (data) => async (dispatch) => {
  dispatch(requestIsFetching())
  try {
    const response = await API.createAccount(data)
    dispatch(successCreateAccount(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const validateEmail = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.validateEmail(data, token)
    dispatch(successValidateEmail(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const updatePhone = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.updatePhone(data, token)
    dispatch(successUpdatePhone(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const validatePhone = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.validatePhone(data, token)
    dispatch(successValidatePhone(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const completeAccount = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.completeAccount(data, token)
    dispatch(successCompleteAccount(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const completeAccountInvitation = (data) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.completeAccountInvitation(data, token)
    dispatch(successCompleteAccount(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

// Re-send validation codes
export const resendValidationSMS = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.resendValidationSMS(data, token)
    dispatch(successResendSmsCode(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}


export const validationSMSTimeLeftChange = (data) => async (dispatch, getState) => {
  dispatch(requestIsFetching())
  try {
    
    dispatch(smsCodeTimeLeftChange(data))
  } catch (err) {
    dispatch(requestError(err.response.data))
  }
}

export const resendValidationEmail = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.resendValidationEmail(data, token)
    dispatch(successResendEmailCode(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}

export const sendTwofaFallbackSms = (data) => async (dispatch, getState) => {
  const token = getState().auth.token
  dispatch(requestIsFetching())
  try {
    const response = await API.sendTwofaFallbackSms(data, token)
    dispatch(successTwofaFallbackSms())
    return Promise.resolve(response)
  } catch (err) {
    // dispatch(requestError(err.response.data))
    return Promise.reject(err.response.data)
  }
}


// Log out
export const logOut = () => (dispatch) => {
  dispatch(requestLogOut())
}
